<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          PODs
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-5"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="abrirDialogPod(null)">
          <v-icon>mdi-plus-circle-outline</v-icon> Nuevo POD
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="listaPod"
        :items-per-page="itemsPerPage"
        :search="search"
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <!-- <v-btn icon color="green" @click="abrirDialogPod(item.POD_ID)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->

          <v-btn icon color="green" :to="`/form_pod/${item.POD_ID}`">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- dialogo POD -->
    <v-dialog v-model="dialogPod" persistent max-width="960px" scrollable>
      <v-card>
        <v-toolbar height="40" color="blue" dark flat fixed>
          <v-icon left class="mt-1">mdi-truck</v-icon>
          <span class="mt-1 headline">POD # {{ datosPodHead.POD_ID }}</span>
          <v-divider></v-divider>
          <v-btn @click="dialogPod = !dialogPod" icon dark
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formPod">
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosPodHead.POD_FECHA"
                    label="Fecha"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field v-model="datosPodHead.AWB_NUM" label="NUM GUIA">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosPodHead.CF_NOMBRE"
                    label="Cliente"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosPodHead.TRANSP_NOMBRE"
                    label="Transportista"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field v-model="datosPodHead.ESTADO_ID" label="Estado">
                  </v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    tabindex="0"
                    autofocus
                    dense
                    v-model="codigoBarraItem"
                    label="Código de barra"
                    ref="inputBarcode"
                    @blur="$refs.inputBarcode.focus()"
                    @input="ingresarCajas()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table :items="itemsPod" :headers="headerItemsPod">
                    <template v-slot:item.opcion="{ item }">
                      <v-btn small color="red" icon
                        ><v-icon left>mdi-delete</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarPod">
              <v-icon left>mdi-content-save</v-icon> Guardar
            </v-btn>

            <v-btn color="blue" dark class="mx-4" small @click="imprimirPod">
              <v-icon left>mdi-printer</v-icon> Imprimir
            </v-btn>

            <v-btn class="mx-8" small @click="dialogPod = !dialogPod">
              <v-icon left>mdi-cancel</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
var intervalbarCode = "";
export default {
  name: "PodLista",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "POD#", value: "POD_ID" },
      { text: "Fecha", value: "POD_FECHA" },
      { text: "Cliente final", value: "CF_NOMBRE" },
      { text: "Transportista", value: "TRANSP_NOMBRE" },
      { text: "MAWB", value: "AWB_NUM" },
      { text: "Estado", value: "ESTADO_ID" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Ingrese un Email válido",
    ],
    listaPod: [],
    options: {},
    pageCount: 0,
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,

    search: null,
    incluirInactivos: false,

    dialogPod: false,
    datosPodHead: {
      POD_ID: "",
      POD_FECHA: "",
      POD_ESTADO: "",
      TRANSP_NOMBRE: "",
    },

    itemsPod: [],
    headerItemsPod: [
      { text: "Opciones", value: "opcion" },
      { text: "HAWB", value: "NUM_GUIA_HIJA" },
      { text: "FINCA", value: "FINCA_NOMBRE" },
      { text: "Descripción", value: "DESCRIPCION" },
      { text: "Tipo de caja", value: "TIPO_CAJA" },
      { text: "Dimensiones", value: "DIM" },
      { text: "Piezas", value: "PIEZAS", align: "end" },
      { text: "Full box", value: "FBE", align: "end" },
    ],

    itemsPerPage: 40,
    currentPage: 1,
    creaPod: true,
    codigoBarraItem: "",
    overlay: false,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),
    ...mapGetters("access", [""]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-pod");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
        },
      })
        .then((res) => {
          //console.log(res);
          this.listaPod = res.data.lista;
          this.pageCount = res.data.pageCount;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    modificarPodHead() {
      this.setUrl("api/modificar-pod-head");
      this.requestApi({
        method: "POST",
        data: {
          pod_head: this.datosPodHead,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
          this.dialogPod = false;
        })
        .then(() => {});
    },

    abrirDialogPod(podId) {
      window.location.target = "_new";
      window.location.href = "/form_pod/" + podId;
      /*this.creaPod = false;
      if (podId == null) {
        this.datosPodHead = {
          POD_ID: "",
          POD_FECHA: "",
          ESTADO_ID: 0,
          TRANSPORTISTA_ID: "",
          CLIENTE_FINAL_ID: "",
          CNEE_ID: "",
          AWB_NUM: "",
        };
        this.creaPod = true;
      }
      this.setUrl("api/cargar-datos-pod");
      this.requestApi({
        method: "GET",
        data: {
          POD_ID: podId,
        },
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.podHead != null) {
            this.datosPodHead = res.data.podHead;
            this.itemsPod = res.data.podItems;
          }

          this.dialogPod = true;
        })
        .then(() => {});*/
    },

    eliminarPod(podId) {
      Vue.swal({
        html: "Está seguro de eliminar el pod ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-pod");
          this.requestApi({
            method: "POST",
            data: {
              POD_ID: podId,
            },
          }).then((res) => {
            //console.log(res);
            this.cargarLista();
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },
    guardarPod() {
      if (!this.$refs.formPod.validate()) {
        return false;
      }
      if (this.creaPod == true) {
        this.crearPod();
      } else {
        this.modificarPod();
      }
    },
    crearPod() {
      console.log(this.datosPod);
      this.setUrl("api/crear-pod");
      this.requestApi({
        method: "POST",
        data: {
          pod_head: this.datosPod,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.dialogPod = false;
          this.cargarLista();
        })
        .then(() => {});
    },

    cargarPod() {
      //console.log(this.datosPod);
      this.setUrl("api/cargar-datos-pod");
      this.requestApi({
        method: "GET",
        data: {
          POD_ID: this.datosPodHead.POD_ID,
        },
      })
        .then((res) => {
          //this.datosPodHead = res.data.podHead;
          this.itemsPod = res.data.podItems;
        })
        .then(() => {});
    },

    ingresarCajas() {
      clearInterval(intervalbarCode);

      intervalbarCode = setTimeout(() => {
        // let obj = this.dataQuotation.items_order.find(
        //   (e) => e.codigoBarra == this.barCodeItem && e.verificado == false
        // );

        // if (typeof obj != "undefined") {
        //   let index = this.dataQuotation.items_order.indexOf(obj);
        //   this.dataQuotation.items_order[index].verificado = true;
        // } else {
        //   Vue.swal({
        //     html: "El producto escaneado no pertence a la cotización",
        //     icon: "warning",
        //     ...this.paramAlertQuestion,
        //   });
        // }

        this.setUrl("api/agregar-pod-item-bodega");
        this.requestApi({
          method: "POST",
          data: {
            ID: this.codigoBarraItem,
            POD_ID: this.datosPodHead.POD_ID,
          },
        })
          .then((res) => {
            //console.log(res);
            this.alertNotification({ param: { html: res.data.msg } });
            //this.cargarLista();
            this.cargarPod();
          })
          .catch(() => {})
          .then(() => {
            this.setLoadingTable(false);
          });
        this.codigoBarraItem = null;
      }, 200);
    },

    imprimirPod() {
      this.setUrl("api/imprimir-pod");
      this.requestApi({
        method: "POST",
        data: {
          POD_ID: this.datosPodHead.POD_ID,
        },
      })
        .then((res) => {
          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + res.data.datos;
          a.download = res.data.nombreArchivo;
          a.click();
          this.itemsSel = [];

          //this.alertNotification({ param: { html: res.data.msg } });
        })
        .then(() => {});
    },
    handlePageChange() {
      this.cargarLista();
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Proof of delivery POD");
    this.cargarLista();
  },
};
</script>
